import { Card, CardContent, Grid } from "@material-ui/core"
import { graphql, Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.css"
import "../styles/index.css"

const ExplorePage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}) => (
  <Layout location={location} title={"Explore"}>
    <SEO title="Explore" />
    <Helmet title={title} />
    <h1>Explore</h1>
    <Grid container spacing={2}>
      {group.map((tag, index) => (
        <Grid key={index} item>
          <Card>
            <CardContent>
              <Link to={`/explore/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue}
              </Link>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Layout>
)

ExplorePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default ExplorePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`
